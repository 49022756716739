@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: sajafFont;
  src: url("./fonts/sajaf-font.ttf");
}

@font-face {
  font-family: sajafFontBold;
  src: url("./fonts/sajaf-font-bold.ttf");
}

body {
  margin: 0;
  font-family: sajafFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #e5e5e5;
}
::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 30px;
}
::-webkit-input-placeholder {
  font-size: 12px;
}

.my-custom-input-class{
  text-align: "center";
  padding: '1rem 1.5rem';
  width:30px;
  height: 100%;
  outline: "none";
  background-image: url('./assets/calenderImage.png');
  background-size: cover;
  background-position: center;
  cursor: pointer;
  outline: none;
}
.my-custom-calender{
  position: relative;
  right: 100px !important;
}

.CountryCallingCodeSelect{
  width: 571px;
  height: 38px;
  font-size: large;
}
.my-GooglePlacesSelect-class{
  width: 571px;
}

